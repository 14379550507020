<template>
  <div class="more-news-container">
    <!-- navbar -->
    <qb-navbar title="公司介绍"></qb-navbar>
    <!-- title -->
    <qb-title title="公司新闻" subtitle="Quanbang Group News"></qb-title>
    <van-tabs class="tabs" v-model="active">
      <van-tab title="公司新闻">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <qb-news-item
              v-for="item in newList"
              :key="item.id"
              :item="item"
              @click.native="$router.push(`/news/detail/${item.id}`)"
            ></qb-news-item>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="媒体动态">内容 2</van-tab>
      <van-tab title="启动大会">内容 3</van-tab>
    </van-tabs>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'MoreNews',
  data() {
    return {
      active: 0,
      newList: [
        {
          id: 1,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 2,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 3,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 4,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 5,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        }
      ],
      loading: false,
      finished: false,
      refreshing: false
    }
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.newList = []
          this.refreshing = false
        }

        this.newList.push({
          id: Math.random(),
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        })
        this.loading = false

        if (this.newList.length >= 6) {
          this.finished = true
        }
      }, 1000)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    }
  }
}
</script>

<style lang="less" scoped>
.more-news-container {
  position: relative;

  .line {
    width: 375px;
    position: absolute;
    border-bottom: 1px solid #1651dd;
    top: 190px;
  }
  .tabs {
    margin-top: 16px;
  }
  ::v-deep .van-tabs__wrap {
    height: 30px;
    .van-tabs__nav {
      justify-content: space-evenly;
    }
    .van-tab {
      padding: 0;
      flex: none;
    }
    .van-tabs__line {
      width: 96px;
      height: 4px;
      background: #1651dd;
    }
    .van-tab__text {
      font-size: 18px;
    }
    .van-tab--active {
      font-size: 18px;
      font-weight: 700;
      color: #010343;
    }
  }
  ::v-deep .van-tabs__content {
    padding-top: 7px;
  }
}
</style>
